import React from 'react';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';
import FilterFooter from './../Components/Elements/FilterFooter';
import FilterOptions from '../Components/Elements/FilterOptions';
import {Typography} from '@material-ui/core';

const PrettoSlider = withStyles({
  root: {
    color: 'primary',
    height: 8,
  },
  thumb: {
    height: 20,
    width: 20,
    backgroundColor: 'primary',
    border: '2px solid currentColor',
    marginTop: -8,
    marginLeft: -12,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 4,
    borderRadius: 4,
  },
  rail: {
    height: 4,
    borderRadius: 4,
  },
})(Slider);

export const FilterModal = ({
  toggleDrawer,
  varietyOptions,
  countryOptions,
  typeOptions,
  setShowFiltering,
  varietySelection,
  countrySelection,
  typeSelection,
  priceData,
  priceSelection,
  setPriceSelection,
  handleFilterSelection,
  showFilter,
  setShowFilter,
}) => {
  const classes = useStyles();

  const handleChange = (event, newPriceSelect) => {
    setPriceSelection(newPriceSelect);
  };

  return (
    <div className="Filter_Modal">
      <h2 style={{textAlign: 'left', width: '90%', marginLeft: 'auto'}}>
        Filter
      </h2>
      <div className={classes.root}>
        <Typography id="range-slider" gutterBottom>
          Price
        </Typography>

        <PrettoSlider
          valueLabelDisplay="auto"
          aria-label="pretto slider"
          value={priceSelection}
          onChange={handleChange}
          min={priceData ? priceData.min : 0}
          max={priceData ? priceData.max : 1000}
        />
      </div>
      <div className={classes.root}>
        {/* <h5>Filters</h5> */}
        <div className="Filter_Option" onClick={() => setShowFilter('Grape')}>
          Grape{' '}
          <span className="small_right_abs">
            {varietySelection?.length === 0 && 'Any'}
          </span>
          {varietySelection?.length > 0 &&
            varietySelection.map((sel) => (
              <div
                style={{textAlign: 'right', marginRight: 25}}
                className="faded_text_smaller"
              >
                {sel.name}
                <span
                  className="small_farright_abs"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleFilterSelection(sel, true, 'Grape');
                  }}
                >
                  <i
                    className="fas fa-times highlight_ex"
                    style={{fontSize: 24}}
                  ></i>
                </span>
              </div>
            ))}
        </div>

        <div className="Filter_Option" onClick={() => setShowFilter('Type')}>
          Type/Color{' '}
          <span className="small_right_abs">
            {typeSelection?.length === 0 && 'Any'}
          </span>
          {typeSelection?.length > 0 &&
            typeSelection.map((sel) => (
              <div
                style={{textAlign: 'right', marginRight: 25}}
                className="faded_text_smaller"
              >
                {sel.name}
                <span
                  className="small_farright_abs"
                  onClick={(e) => {
                    handleFilterSelection(sel, true, 'Type');
                    e.stopPropagation();
                  }}
                >
                  <i
                    className="fas fa-times highlight_ex"
                    style={{fontSize: 24}}
                  ></i>
                </span>
              </div>
            ))}
        </div>

        <div className="Filter_Option" onClick={() => setShowFilter('Country')}>
          Country
          <span className="small_right_abs">
            {!countrySelection?.length && 'Any'}
          </span>
          {countrySelection?.length > 0 &&
            countrySelection.map((sel) => (
              <div
                style={{textAlign: 'right', marginRight: 25}}
                className="faded_text_smaller"
              >
                {sel.country_name ? sel.country_name : sel.name}
                <span
                  className="small_farright_abs"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleFilterSelection(sel, true, 'Country');
                  }}
                >
                  <i
                    className="fas fa-times highlight_ex"
                    style={{fontSize: 24}}
                  ></i>
                </span>
              </div>
            ))}
        </div>
      </div>
      <FilterOptions
        setShowFilter={setShowFilter}
        toggleDrawer={toggleDrawer}
        showFilter={showFilter}
        varietyOptions={varietyOptions}
        countryOptions={countryOptions}
        typeOptions={typeOptions}
        varietySelection={varietySelection}
        countrySelection={countrySelection}
        typeSelection={typeSelection}
        handleFilterSelection={handleFilterSelection}
      />
      <FilterFooter
        toggleDrawer={toggleDrawer}
        setShowFiltering={setShowFiltering}
      />
    </div>
  );
};
const useStyles = makeStyles({
  root: {
    width: 300,
  },
  list: {
    fontSize: 19,
  },
  fullList: {
    width: 'auto',
  },
});
