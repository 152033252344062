import React from 'react';
import {Button} from '@material-ui/core';

const ReserveFooter = ({toggleDrawer, bottleToReserve, handleReservation}) => {
  return (
    <div className="bottom_navigation flexJustify blocked_bg">
      <Button
        variant="contained"
        color="secondary"
        size="large"
        onClick={toggleDrawer('form', false)}
      >
        Cancel
      </Button>
      <Button
        variant="contained"
        color="primary"
        size="large"
        onClick={(e) => {
          toggleDrawer('bottom', false)(e);
          handleReservation();
        }}
      >
        Reserve
      </Button>
    </div>
  );
};
export default ReserveFooter;
