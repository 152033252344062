import React from 'react';
import clsx from 'clsx';
import {makeStyles} from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import {FormControlLabel, Checkbox} from '@material-ui/core';

export const CategoryPicker = ({
  toggleDrawer,
  anchor,
  categories,
  handleCategorySelection,
  showFullList,
  restaurantName,
  categorySelection,
}) => {
  const classes = useStyles();

  return (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
      })}
      role="presentation"
    >
      <List>
        <ListItem key={'Categories'}>
          <h3 style={{marginBottom: 0}}>Categories</h3>
        </ListItem>
        <Divider />
        <ListItem
          style={{marginLeft: 18, marginTop: 20}}
          button
          key={'View All'}
          onClick={showFullList}
        >
          <span style={{fontSize: 15, fontweight: 900}}>View All Items</span>
        </ListItem>
        {categories.map((cati) => {
          let contained = false;
          categorySelection.forEach((cat) => {
            if (cat.id === cati.id) contained = true;
          });
          return (
            <ListItem style={{marginLeft: 5}} button key={cati.id}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={contained}
                    name="checkedB"
                    color="primary"
                    onChange={() => handleCategorySelection(cati, contained)}
                  />
                }
                label={cati.name}
              />
            </ListItem>
          );
        })}
      </List>
    </div>
  );
};

const useStyles = makeStyles({
  list: {
    width: 250,
    fontSize: 19,
  },
  fullList: {
    width: 'auto',
  },
});
