import React from 'react';
import {Button} from '@material-ui/core';

const FilterFooter = ({toggleDrawer, setShowFiltering}) => {
  return (
    <div className="bottom_navigation flexJustify blocked_bg">
      <Button
        variant="contained"
        color="secondary"
        size="large"
        onClick={() => setShowFiltering(false)}
        onMouseDown={toggleDrawer('bottom', false)}
      >
        Cancel
      </Button>
      <Button
        variant="contained"
        color="primary"
        size="large"
        onClick={(e) => {
          setShowFiltering(true);
          toggleDrawer('bottom', false)(e);
        }}
      >
        Show Results
      </Button>
    </div>
  );
};
export default FilterFooter;
