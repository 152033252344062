import React, {useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {TextField} from '@material-ui/core';
import ReserveFooter from '../Components/Elements/ReserveFooter';
import {wineListAPI} from '../API/WineListAPI';

export const ReserveForm = ({toggleDrawer, bottleToReserve, restaurantId}) => {
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [reserveDate, setReserveDate] = useState('');
  const [note, setNote] = useState('');
  const classes = useStyles();

  const handleReservation = () => {
    const bottleIds = bottleToReserve.map((item) => item.wine_bottle.id);
    console.log(restaurantId, bottleIds, name, phone, reserveDate, note);
    wineListAPI.reserveItems(
      restaurantId,
      bottleIds,
      name,
      phone,
      reserveDate,
      note,
    );
  };

  return (
    <div className="Filter_Modal">
      <h2 style={{textAlign: 'left', width: '90%', marginLeft: 'auto'}}>
        Reserving:
      </h2>
      <div className={classes.root}>
        <div className="reserving_items">
          {bottleToReserve.map((res) => (
            <div class="reserve_item">{res.item_name}</div>
          ))}
        </div>
      </div>

      <div className={classes.root}>
        <form className={classes.container} noValidate>
          <TextField
            error={false}
            id="outlined-full-width"
            label="Name"
            style={{margin: 8, marginBottom: 25}}
            placeholder="Enter Name"
            //helperText="Name"
            type="name"
            fullWidth
            margin="normal"
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
          />

          <TextField
            error={false}
            id="outlined-full-width"
            label="Phone"
            type="phone"
            style={{margin: 8, marginBottom: 25}}
            placeholder="Enter Phone"
            fullWidth
            value={phone}
            onChange={(e) => {
              setPhone(e.target.value);
            }}
          />

          <TextField
            id="datetime-local"
            label="Reservation Time"
            type="datetime-local"
            defaultValue="2020-08-24T10:30"
            className={classes.textField}
            InputLabelProps={{
              shrink: true,
            }}
            value={reserveDate}
            onChange={(e) => {
              setReserveDate(e.target.value);
            }}
          />

          <TextField
            error={false}
            id="outlined-full-width"
            label="Note"
            type="text"
            style={{margin: 8, marginBottom: 25}}
            placeholder="Add Note"
            fullWidth
            multiline={true}
            rows={4}
            value={note}
            onChange={(e) => {
              setNote(e.target.value);
            }}
          />
        </form>
      </div>

      <ReserveFooter
        toggleDrawer={toggleDrawer}
        bottleToReserve={bottleToReserve}
        handleReservation={handleReservation}
        // setShowFiltering={setShowFiltering}
      />
    </div>
  );
};
const useStyles = makeStyles((theme) => ({
  root: {
    width: 300,
  },
  list: {
    fontSize: 19,
  },
  fullList: {
    width: 'auto',
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '90%',
    maxWidth: '800px',
  },
}));
