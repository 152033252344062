import React, {useCallback, useState, useEffect, useContext} from 'react';
import {fade, makeStyles} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import InputBase from '@material-ui/core/InputBase';
import _ from 'lodash';
import MenuIcon from '@material-ui/icons/Menu';
import FilterListIcon from '@material-ui/icons/FilterList';
import {Fade, InputAdornment} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import {RestaurantContext} from '../Context/RestaurantContext';

const useStyles = makeStyles((theme) => ({
  appBar: {
    justifyContent: 'space-between',
  },
  logo: {
    width: '200px',
  },
  title: {
    color: '#000',
    fontWeight: 'bold',
  },
  grow: {
    flexGrow: 1,
    background: 'white',
  },
  menuButton: {
    color: fade(theme.palette.common.black, 0.95),
  },

  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: 0,
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(0),
      width: 'auto',
    },
    color: theme.palette.common.black,
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    width: '100%',
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
  actionButtons: {
    display: 'flex',
  },
}));

export default function TopNav({
  toggleDrawer,
  anchor,
  state,
  searchQuery,
  setSearchQuery,
  setQuerying,
  clearSearch,
  setClearSearch,
}) {
  const [currentTerm, setCurrentTerm] = useState('');
  const [toggleSearch, setToggleSearch] = useState(false);
  const [restaurant, setRestaurant] = useContext(RestaurantContext);

  const delayedQuery = useCallback(
    _.debounce((q) => {
      setSearchQuery(q);
      setQuerying(false);
    }, 500),
    [],
  );
  const handleSearch = (e) => {
    // setSearchQuery(e.target.value);
    setQuerying(true);
    delayedQuery(e.target.value);
    setCurrentTerm(e.target.value);
  };

  const classes = useStyles();

  useEffect(() => {
    setSearchQuery('');
    setCurrentTerm('');
  }, [clearSearch, setSearchQuery]);

  const handleEnter = (event) => {
    if (event.key === 'Enter') {
      event.target.blur();
    }
  };

  const stopSearching = (e) => {
    toggleDrawer('top', false)(e);
    setToggleSearch(false);
  };

  const startSearching = (e) => {
    toggleDrawer('top', true)(e);
    setToggleSearch(true);
  };

  const handleFocus = (event) => {
    event.target.focus();
  };

  return (
    <div className={classes.grow}>
      <AppBar elevation={1} position="static" style={{backgroundColor: '#fff'}}>
        <Toolbar className={classes.appBar}>
          <div style={{display: 'flex', alignItems: 'center'}}>
            {!toggleSearch && (
              <IconButton
                edge="start"
                onClick={toggleDrawer('left', true)}
                className={classes.menuButton}
                color="inherit"
                aria-label="open drawer"
              >
                <MenuIcon />
              </IconButton>
            )}
            {toggleSearch && (
              <IconButton
                edge="start"
                className={classes.menuButton}
                color="inherit"
                aria-label="open drawer"
                onClick={(e) => {
                  stopSearching(e);
                }}
              >
                <i className="fas fa-arrow-left"> </i>
              </IconButton>
            )}

            {!toggleSearch && (
              <div>
                {restaurant.logo && (
                  <img className={classes.logo} src={restaurant.logo}></img>
                )}
                {restaurant.logo === '' && (
                  <span className={classes.title}>{restaurant.name}</span>
                )}
              </div>
            )}
          </div>
          {toggleSearch && (
            <div
              className={classes.search}
              onClick={(e) => {
                handleFocus(e);
                toggleDrawer('top', true);
              }}
              onKeyDown={handleEnter}
            >
              <InputBase
                placeholder="Search…"
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                endAdornment={
                  currentTerm.length > 0 && (
                    <InputAdornment
                      position="end"
                      onClick={(e) => {
                        stopSearching(e);
                      }}
                    >
                      <span
                        className="right_abs_icon"
                        onMouseDown={() => {
                          setClearSearch(!clearSearch);
                        }}
                      >
                        <i
                          className="fas fa-times highlight_ex"
                          style={{fontSize: 24}}
                        ></i>
                      </span>
                    </InputAdornment>
                  )
                }
                value={currentTerm}
                inputProps={{'aria-label': 'search'}}
                onChange={handleSearch}
              />
            </div>
          )}
          {!toggleSearch && (
            <div className={classes.actionButtons}>
              <IconButton
                edge="end"
                onClick={(e) => {
                  handleFocus(e);
                  startSearching(e);
                }}
              >
                <SearchIcon className={classes.menuButton}></SearchIcon>
              </IconButton>
            </div>
          )}
        </Toolbar>
      </AppBar>
    </div>
  );
}
