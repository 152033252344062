import React, {useState, useEffect, useRef} from 'react';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import Fuse from 'fuse.js';
import {GooSpinner} from 'react-spinners-kit';
import {WineItem} from '../Components/WineItem';
import {Box, Paper} from '@material-ui/core';
import CusBottomNavigation from '../Components/BottomNavigation';

export const SearchModal = ({
  toggleDrawer,
  wineList,
  searchQuery,
  querying,
  handleFavorite,
  favoritesList,
  currentRestaurant,
}) => {
  const [results, setResults] = useState([]);
  const filterRef = useRef(null);
  useEffect(() => {
    const fuseoptions = {
      shouldSort: true,
      includeScore: true,
      includeMatches: true,
      threshold: 0.6,
      maxPatternLength: 29,
      minMatchCharLength: 3,
      keys: ['item_name'],
    };
    filterRef.current = new Fuse(wineList, fuseoptions);
  }, [wineList]);

  useEffect(() => {
    const queriedList = filterRef.current.search(searchQuery);
    setResults(queriedList);
  }, [searchQuery]);

  useEffect(() => {}, [results]);

  return (
    <>
      <List>
        <h2 className="pad4">
          {searchQuery && searchQuery.length > 0
            ? 'Search Results For:'
            : 'Type to begin searching...'}
          {/* <span
          className="right_abs_icon"
          onClick={() => setClearSearch(!clearSearch)}
        >
          <HighlightOffTwoToneIcon
            color="primary"
            fontSize="large"
            onClick={toggleDrawer("top", false)}
          />
        </span> */}
        </h2>

        <h3 className="pad4">
          {searchQuery}
          <span className="right_abs_icon">
            <GooSpinner size={50} color="lightblue" loading={querying} />
          </span>
        </h3>
        <Divider />
        <div className="wineList_Container">
          {results.map((wineResult) => (
            <WineItem
              category={wineResult.item}
              handleFavorite={handleFavorite}
              favoritesList={favoritesList}
              search={true}
            />
          ))}
          {results && results.length > 0 && (
            <small style={{textAlign: 'center', width: '100%'}}>
              This is the end of Search Results...{' '}
              <span role="img" aria-label="crying cat emoji">
                😿
              </span>
            </small>
          )}
        </div>
      </List>
      <Box m={6.5} className="search_padding" />
      <Paper
        elevation={4}
        className="bottom_navigation"
        onClick={toggleDrawer('top', false)}
      >
        <CusBottomNavigation currentRestaurant={currentRestaurant} />
      </Paper>
    </>
  );
};
