import React, {useState, useEffect} from 'react';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import {Link} from 'react-router-dom';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import StarIcon from '@material-ui/icons/Star';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import ViewListIcon from '@material-ui/icons/ViewList';
import {useLocation} from 'react-router-dom';

export default function CusBottomNavigation({currentRestaurant}) {
  const [value, setValue] = useState(0);

  let location = useLocation();
  useEffect(() => {}, [location]);

  return (
    <BottomNavigation
      value={value}
      onChange={(event, newValue) => {
        setValue(newValue);
      }}
      showLabels
      className="bottom_nav_width"
    >
      <BottomNavigationAction
        component={Link}
        to={`/id/${currentRestaurant}`}
        icon={<ViewListIcon />}
        className="bigger_text"
      />

      <BottomNavigationAction
        component={Link}
        to={`/favorites/${currentRestaurant}`}
        icon={value === 1 ? <StarIcon /> : <StarBorderIcon />}
        className="bigger_text"
      />

      {/* <BottomNavigationAction
        to="/account"
        component={Link}
        label="Account"
        icon={<PermContactCalendarIcon />}
        className="bigger_text"
      /> */}
    </BottomNavigation>
  );
}
