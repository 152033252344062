import React, {useState, useEffect} from 'react';
import {
  Drawer,
  ListItem,
  List,
  Divider,
  Box,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

const FilterOptions = ({
  showFilter,
  setShowFilter,
  toggleDrawer,
  varietyOptions,
  countryOptions,
  typeOptions,
  handleFilterSelection,
  varietySelection,
  countrySelection,
  typeSelection,
}) => {
  const [filterList, setFilterList] = useState([]);

  useEffect(() => {
    if (showFilter === 'Grape') {
      setFilterList(varietyOptions);
    } else if (showFilter === 'Type') {
      setFilterList(typeOptions);
    } else if (showFilter === 'Country') {
      const result = [];
      const map = new Map();

      countryOptions.forEach((country) => {
        if (!map.has(country.country_name)) {
          map.set(country.country_name, true); // set any value to Map
          result.push({
            country_name: country.country_name,
            country_code: country.country_code,
          });
        }
      });
      setFilterList(result);
    }
  }, [showFilter, varietyOptions, countryOptions, typeOptions]);

  return (
    <Drawer
      anchor={'bottom'}
      open={showFilter}
      onClose={() => setShowFilter(false)}
    >
      <div className="Filter_Modal">
        <Box m={6.5} />
        <h3 className="Filter_Option_Title">
          <ArrowBackIosIcon
            color="#424242"
            className="right_margin"
            onClick={() => setShowFilter(false)}
          />
          {showFilter}
        </h3>
        <Divider />
        <List
          style={{
            padding: '5%',
            paddingBottom: 65,
            overflowY: 'scroll',
            height: '80%',
            width: '90%',
          }}
        >
          {filterList
            .sort((a, b) => (b.name > a.name ? -1 : 1))
            .map((option) => {
              let contained = false;
              varietySelection.forEach((sel) => {
                if (sel.id === option.id) contained = true;
              });
              countrySelection.forEach((sel) => {
                if (sel.country_code === option.country_code) contained = true;
              });
              typeSelection.forEach((sel) => {
                if (sel.id === option.id) contained = true;
              });
              return (
                <ListItem button key={option.id}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={contained}
                        name="checkedB"
                        color="primary"
                        onClick={() => {
                          handleFilterSelection(option, contained);
                        }}
                      />
                    }
                    label={
                      option.country_name ? option.country_name : option.name
                    }
                  />
                </ListItem>
              );
            })}
          <Box m={6.5} />
        </List>
      </div>{' '}
      {/* <FilterFooter toggleDrawer={toggleDrawer} setShowFiltering={setShowFiltering} /> */}
    </Drawer>
  );
};
export default FilterOptions;
