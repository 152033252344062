import React, {useState, createContext} from 'react';

const RestaurantContext = createContext();

function RestaurantProvider(props) {
  const [restaurant, setRestaurant] = useState([]);

  return (
    <RestaurantContext.Provider value={[restaurant, setRestaurant]}>
      {props.children}
    </RestaurantContext.Provider>
  );
}

export {RestaurantContext, RestaurantProvider};
