import React from 'react';
import {WineItem} from './WineItem';
import Sticky from 'react-stickynode';

export default function WineList({
  wineList,
  handleFavorite,
  favoritesList,
  showFiltering,
  countrySelection,
  varietySelection,
  bottleToReserve,
  setBottlesToReserve,
  favorites,
}) {
  if (wineList && wineList?.length > 0) {
    return wineList.map((category, index) => (
      <>
        {(category?.wines?.length > 0 || category?.contains?.length > 0) && (
          <div
            style={{
              fontWeight:
                900 - (!isNaN(category.depth) ? category.depth : 1) * 100,
              borderBottom:
                category.depth < 3
                  ? '1px solid rgba(120,120,120,0.4)'
                  : '0px solid grey',
              position: 'relative',
              background: category.depth < 2 ? 'white' : 'rgba(0, 0, 0, 0)',
              top: 0,
              // zIndex: 150,
              paddingLeft: '4px',
              paddingTop: category.depth < 2 ? '0.5em 0' : 0,
              marginBottom: '0.5em',
            }}
          >
            {category.name !== '.' ? category.name : ''}
          </div>
        )}

        <div>
          {category?.wines?.length > 0 &&
            category.wines.map((wine, index2) => {
              if (!showFiltering) {
                return (
                  <WineItem
                    key={wine.id}
                    category={category}
                    wine={wine}
                    handleFavorite={handleFavorite}
                    favoritesList={favoritesList}
                    bottleToReserve={bottleToReserve}
                    setBottlesToReserve={setBottlesToReserve}
                    favorites={favorites}
                  />
                );
              } else {
                if (
                  (wine &&
                    wine.wine_variety &&
                    wine.wine_variety.id === varietySelection.id) ||
                  (wine &&
                    wine.wine_sub_region &&
                    wine.wine_sub_region.id === countrySelection.id)
                ) {
                  return (
                    <WineItem
                      category={category}
                      wine={wine}
                      handleFavorite={handleFavorite}
                      favoritesList={favoritesList}
                      bottleToReserve={bottleToReserve}
                      setBottlesToReserve={setBottlesToReserve}
                      favorites={favorites}
                    />
                  );
                } else return null;
              }
            })}
          {category?.item_name && (
            <WineItem
              category={category}
              handleFavorite={handleFavorite}
              favoritesList={favoritesList}
              bottleToReserve={bottleToReserve}
              setBottlesToReserve={setBottlesToReserve}
              favorites={favorites}
            />
          )}
          {category?.contains?.length > 0
            ? category.contains.map((child, index3) => (
                <>
                  {(child?.wines?.length > 0 ||
                    (child?.contains?.length > 0 &&
                      child?.contains?.wines?.length > 0) ||
                    (child?.contains?.length > 0 &&
                      child?.contains?.wines?.length > 0)) && (
                    <Sticky enabled={true} top={56} innerClass="sticky-header">
                      <div style={{paddingTop: 5}}>
                        <b>{child.name} </b>
                      </div>
                    </Sticky>
                  )}

                  {child?.wines?.length > 0 && (
                    <WineList
                      wineList={child.wines}
                      handleFavorite={handleFavorite}
                      favoritesList={favoritesList}
                    />
                  )}
                  {child?.contains?.length > 0 && (
                    <WineList
                      wineList={child.contains}
                      handleFavorite={handleFavorite}
                      favoritesList={favoritesList}
                    />
                  )}
                </>
              ))
            : null}
        </div>
      </>
    ));
  } else return null;
}
