import React, {useState, useEffect} from 'react';
import './App.css';
import TopNav from './Components/TopNav';

import Paper from '@material-ui/core/Paper';
import CusBottomNavigation from './Components/BottomNavigation';
import {createMuiTheme} from '@material-ui/core/styles';
import {ThemeProvider} from '@material-ui/styles';
import ListView from './Components/ListView';
import {Route, Switch, Redirect} from 'react-router-dom';
import './sentry.js';
import LocalForage from 'localforage';
import {RestaurantProvider} from './Context/RestaurantContext';

export default function TemporaryDrawer() {
  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    form: false,
  });
  const [searchQuery, setSearchQuery] = useState('');
  const [clearSearch, setClearSearch] = useState(false);
  const [querying, setQuerying] = useState(false);
  const [currentRestaurant, setCurrentRestaurant] = useState(null);

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setState({...state, [anchor]: open});
  };

  // Clear possibly Buggy old LocalForage
  // The recent Bug where clicking a start selected all items - actually added a container to favorites.
  // We are clearing that out here.
  useEffect(() => {
    LocalForage.getItem('Version').then((res) => {
      if (res <= 0.4) {
        LocalForage.clear().then(function () {
          LocalForage.setItem('Version', 0.5);
        });
      }
    });
  }, []);

  return (
    <RestaurantProvider>
      <ThemeProvider theme={customPallette}>
        <div className="Outer_Bevrly">
          <div className="Top_Nav_Outer">
            <TopNav
              toggleDrawer={toggleDrawer}
              setClearSearch={setClearSearch}
              currentRestaurant={currentRestaurant}
              anchor={'left'}
              state={state}
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
              setQuerying={setQuerying}
              clearSearch={clearSearch}
            />
          </div>
          {/* 
        <div className="TopNav_Spacer" /> */}
          <div className="Main_List_Container">
            <Switch>
              <Route exact path={['/id', '/id/:id']}>
                <ListView
                  querying={querying}
                  state={state}
                  toggleDrawer={toggleDrawer}
                  clearSearch={clearSearch}
                  setClearSearch={setClearSearch}
                  searchQuery={searchQuery}
                  setCurrentRestaurant={setCurrentRestaurant}
                  currentRestaurant={currentRestaurant}
                  favorites={false}
                />
              </Route>

              <Route exact path="/favorites/:id">
                <ListView
                  querying={querying}
                  state={state}
                  toggleDrawer={toggleDrawer}
                  clearSearch={clearSearch}
                  setClearSearch={setClearSearch}
                  searchQuery={searchQuery}
                  setCurrentRestaurant={setCurrentRestaurant}
                  currentRestaurant={currentRestaurant}
                  favorites={true}
                />
              </Route>
              <Route exact path="/account">
                <h3>Accounts Coming Soon...</h3>
              </Route>
              <Route render={() => <Redirect to="/id" />} />
            </Switch>
          </div>
          {/* <Box m={6.5} /> */}
          <Paper elevation={4} className="bottom_navigation">
            <CusBottomNavigation currentRestaurant={currentRestaurant} />
          </Paper>
        </div>
      </ThemeProvider>
    </RestaurantProvider>
  );
}

const customPallette = createMuiTheme({
  palette: {
    primary: {
      // main: "#ff9800",
      main: '#238cd1',
      dark: '#ffe0b2',
      contrastText: '#fafafa',
    },
    secondary: {
      light: '#0277bd',
      main: '#fafafa',
      dark: '#b3e5fc',
      contrastText: '#212121',
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
});
