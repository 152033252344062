import React, {useContext, useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import isUUID from 'is-uuid';
import LocalForage from 'localforage';
import Paper from '@material-ui/core/Paper';
import Drawer from '@material-ui/core/Drawer';
import {CategoryPicker} from '../Modals/CategoryPicker';
import {SearchModal} from '../Modals/SearchModal';
import {FilterModal} from '../Modals/FilterModal';
import Fade from '@material-ui/core/Fade';
import {wineListAPI} from '../API/WineListAPI';
import WineList from './WineList';
import {MagicSpinner} from 'react-spinners-kit';
import {useCallback} from 'react';
import {Button, Chip} from '@material-ui/core';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import MapIcon from '@material-ui/icons/Map';
import StyleIcon from '@material-ui/icons/Style';
import TonalityIcon from '@material-ui/icons/Tonality';
import {ReserveForm} from '../Modals/ReserveForm';
import {RestaurantContext} from '../Context/RestaurantContext';
import Fab from '@material-ui/core/Fab';
import FilterListIcon from '@material-ui/icons/FilterList';

export default function ListView({
  querying,
  state,
  toggleDrawer,
  searchQuery,
  setCurrentRestaurant,
  favorites,
  currentRestaurant,
}) {
  const [categories, setCategories] = useState([]);
  const [flatWineList, setFlatWineList] = useState([]);
  const [filteredList, setFilteredList] = useState(null);
  const [categorySelection, setCategorySelection] = useState([]);
  const [loading, setLoading] = useState(false);
  const [favoritesList, setFavoritesList] = useState([]);
  const [varietyOptions, setVarietyOptions] = useState([]);
  const [countryOptions, setCountryOptions] = useState([]);
  const [typeOptions, setTypeOptions] = useState([]);
  const [varietySelection, setVarietySelection] = useState([]);
  const [countrySelection, setCountrySelection] = useState([]);
  const [typeSelection, setTypeSelection] = useState([]);
  const [showFiltering, setShowFiltering] = useState(false);
  const [wineList, setWineList] = useState([]);
  const [restaurantName, setRestaurantName] = useState('');
  const [restaurantLogo, setRestaurantLogo] = useState('');
  const [chosenCat, setChosenCat] = useState(null);
  const [priceData, setPriceData] = useState({min: 0, max: 0, pArray: []});
  const [priceSelection, setPriceSelection] = useState([0, 50000]);
  const [showFilter, setShowFilter] = useState(null);
  const [bottleToReserve, setBottlesToReserve] = useState([]);
  const [restaurant, setRestaurant] = useContext(RestaurantContext);

  let {id} = useParams();

  const handleCategorySelection = (categoryObj, contained) => {
    if (!contained) {
      setCategorySelection((oldArray) => [...oldArray, {...categoryObj}]);
    } else {
      setCategorySelection((oldArray) => {
        let newArray = oldArray.filter((item) => item.id !== categoryObj.id);
        return newArray;
      });
    }
  };

  useEffect(() => {
    if (categorySelection.length > 0) {
      let newList = [];
      categorySelection.forEach((chosen) => {
        let list = wineList.filter((wine) => wine.block === chosen.block);
        newList.push(...list);
      });

      setFilteredList(newList);
      setChosenCat(categorySelection);
    }
  }, [categorySelection, wineList]); // remove wineList if having issues

  const showFullList = () => {
    setCategorySelection([]);
    setFilteredList(null);
    setChosenCat(null);
  };

  const handleFavorite = (favoredItem, addToList) => {
    if (addToList) {
      setFavoritesList((oldArray) => [...oldArray, {...favoredItem}]);
    } else {
      setFavoritesList((oldArray) => {
        let newArray = oldArray.filter((item) => item.id !== favoredItem.id);
        return newArray;
      });
    }
  };
  useEffect(() => {
    id &&
      favoritesList.length > 0 &&
      LocalForage.setItem(`favs_${currentRestaurant}`, favoritesList);
  }, [favoritesList, currentRestaurant, id]); // remove currentRestaurant, id is having problems

  useEffect(() => {
    if (id && id.length > 4 && !favorites) {
      LocalForage.setItem('Recent_Menu', id);
    }
  }, [id, favorites]); // remove favorites if having problems

  useEffect(() => {
    favorites && window.scrollTo(0, 0);
  }, [favorites]);

  useEffect(() => {
    let restaurant_id;

    if (isUUID.v4(id) && id) {
      setLoading(true);
      restaurant_id = id;
      LocalForage.getItem('Version').then((res) => {
        if (res >= 0.5) {
          LocalForage.getItem(`Recent_Name_` + restaurant_id).then(
            (value) => value && setRestaurantName(value),
          );
          LocalForage.getItem(`Recent_Logo_` + restaurant_id).then(
            (value) => value && setRestaurantLogo(value),
          );
          LocalForage.getItem(`Recent_Categories_` + restaurant_id).then(
            (value) => value && setCategories(value),
          );
          LocalForage.getItem(`Recent_FlatList_` + restaurant_id).then(
            (value) => {
              value && setFlatWineList(value);
              const priceArray = value
                ? value.map((bottle) => bottle && bottle.price)
                : [];
              setPriceData({
                min: Math.min(...priceArray),
                max: Math.max(...priceArray),
                pArray: priceArray,
              });
              setPriceSelection([
                Math.min(...priceArray),
                Math.max(...priceArray),
              ]);
            },
          );
          LocalForage.getItem(`Recent_Countries_` + restaurant_id).then(
            (value) => value && setCountryOptions(value),
          );
          LocalForage.getItem(`Recent_Types_` + restaurant_id).then(
            (value) => value && setTypeOptions(value),
          );
          LocalForage.getItem(`Recent_varieties_` + restaurant_id).then(
            (value) => value && setVarietyOptions(value),
          );
          LocalForage.getItem(`Recent_WineList_` + restaurant_id).then(
            (value) => {
              value && value.length > 0 && setLoading(false);
              value && value.length > 0 && setWineList(value);
            },
          );

          LocalForage.getItem(`favs_${restaurant_id}`).then((value) => {
            return value ? setFavoritesList(value) : null;
          });
        }
      });
      setCurrentRestaurant(restaurant_id);
    } else {
      LocalForage.getItem('Recent_Menu').then((value) => {
        restaurant_id = value ? value : restaurant_id;
      });
    }

    wineListAPI.getBevWineList(restaurant_id).then((res) => {
      setLoading(false);
      setRestaurant({
        id: restaurant_id,
        name: res.data.restaurant_name,
        logo: res.data.logo_image_url,
      });
      setRestaurantName(res.data.restaurant_name);
      setRestaurantLogo(res.data.logo_image_url);
      setWineList(res.data.sections);
      console.log('res', res);

      // LocalForage.setItem(
      //   `Recent_Name_` + restaurant_id,
      //   res.data.restaurant_name,
      // );
      // LocalForage.setItem(
      //   `Recent_WineList_` + restaurant_id,
      //   res.data.sections,
      // );
      // LocalForage.setItem(
      //   `Recent_Logo_` + restaurant_id,
      //   res.data.logo_image_url,
      // );
    });
    wineListAPI.getCategoryList(restaurant_id).then((res) => {
      setCategories(res.data.sections);
      // LocalForage.setItem(
      //   `Recent_Categories_` + restaurant_id,
      //   res.data.sections,
      // );
    });
    wineListAPI.getFlatList(restaurant_id).then((res) => {
      if (res && res.data && res.data.items) {
        setFlatWineList(res.data.items);
        // LocalForage.setItem(`Recent_FlatList_` + restaurant_id, res.data.items);
        let varities = [],
          countries = [],
          types = [];
        for (let bottle of res.data.items) {
          bottle.wine &&
            !!bottle.wine.wine_variety &&
            !varities.some((e) => e.id === bottle.wine.wine_variety.id) &&
            varities.push(bottle.wine.wine_variety);
          bottle.wine &&
            !!bottle.wine.wine_sub_region &&
            !countries.some((e) => e.id === bottle.wine.wine_sub_region.id) &&
            countries.push(bottle.wine.wine_sub_region);
          bottle.wine &&
            !!bottle.wine.wine_type &&
            !types.some((e) => e.id === bottle.wine.wine_type.id) &&
            types.push(bottle.wine.wine_type);
        }

        const priceArray = res.data.items.map((bottle) => bottle.price);
        setPriceData({
          min: Math.min(...priceArray),
          max: Math.max(...priceArray),
          pArray: priceArray,
        });
        setPriceSelection([Math.min(...priceArray), Math.max(...priceArray)]);
        setTypeOptions(types);
        setCountryOptions(countries);
        setVarietyOptions(varities);
        LocalForage.setItem(`Recent_Countries_` + restaurant_id, countries);
        LocalForage.setItem(`Recent_varieties_` + restaurant_id, varities);
        LocalForage.setItem(`Recent_Types_` + restaurant_id, types);
      }
    });
  }, [id, setCurrentRestaurant, setRestaurant]); // remove id, setCurrentRestaurant if having probs

  // useEffect(() => {
  //   //check if favs still in list
  //   if (flatWineList && flatWineList.length > 0) {
  //     let remainingFavs = favoritesList.filter((fav) =>
  //       flatWineList.some((e) => e.wine.id === fav.wine.id),
  //     ); // TODO - place the removed favs back in but give them an opacity faded marker
  //     setFavoritesList(remainingFavs);
  //   }
  // }, [flatWineList]);

  const handleFilterSelection = useCallback(() => {
    let newList = [];
    flatWineList.forEach((wine) => {
      if (
        (wine?.wine?.wine_variety &&
          varietySelection &&
          varietySelection.some(
            (item) => item?.id === wine?.wine?.wine_variety?.id,
          )) ||
        (wine?.wine?.wine_type &&
          typeSelection &&
          typeSelection.some((item) => item?.id === wine?.wine?.wine_type?.id))
      ) {
        newList.push(wine);
      } else if (varietySelection === [] && typeSelection === []) {
        newList.push(wine);
      }
    });

    let newerList = [];
    newList.forEach((wine) => {
      if (
        wine?.wine?.wine_sub_region &&
        countrySelection.length > 0 &&
        countrySelection.some(
          (item) =>
            item?.country_code === wine?.wine?.wine_sub_region?.country_code ||
            item?.country_name === wine?.wine?.wine_sub_region?.country_name,
        )
      ) {
        newerList.push(wine);
      } else if (countrySelection.length === 0) {
        newerList.push(wine);
      }
    });
    let priceFilteredList =
      newerList.length > 0
        ? newerList.filter(
            (wine) =>
              wine.price >= priceSelection[0] &&
              wine.price <= priceSelection[1] &&
              wine,
          )
        : newList.length > 0
        ? newList.filter(
            (wine) =>
              wine.price >= priceSelection[0] &&
              wine.price <= priceSelection[1] &&
              wine,
          )
        : flatWineList.filter(
            (wine) =>
              wine.price >= priceSelection[0] &&
              wine.price <= priceSelection[1] &&
              wine,
          );
    if (
      priceData.min === priceSelection[0] &&
      priceSelection.max === priceSelection[1]
    ) {
      setFilteredList(newerList.length > 0 ? newerList : newList);
    } else {
      priceFilteredList &&
        // priceFilteredList.length > 0 &&
        setFilteredList(priceFilteredList);
    }
  }, [
    flatWineList,
    priceData.min,
    priceSelection,
    varietySelection,
    typeSelection,
    countrySelection,
  ]);

  useEffect(() => {
    if (showFiltering) {
      handleFilterSelection();
    } else {
      showFullList(); // clear list
    }
  }, [showFiltering, handleFilterSelection]); //remove handleFilterSelection if having probs

  const userFilterSelection = (selection, contained, filter = false) => {
    if (showFilter === 'Grape' || filter === 'Grape') {
      if (!contained) {
        setVarietySelection((oldArray) => [...oldArray, {...selection}]);
      } else {
        setVarietySelection((oldArray) => {
          let newArray = oldArray.filter((item) => item.id !== selection.id);
          if (
            newArray.length === 0 &&
            countrySelection.length === 0 &&
            typeSelection.length === 0 &&
            priceSelection[0] === priceData.min &&
            priceSelection[1] === priceData.max
          )
            setShowFiltering(false);
          return newArray;
        });
      }
    } else if (showFilter === 'Country' || filter === 'Country') {
      if (!contained) {
        setCountrySelection((oldArray) => [...oldArray, {...selection}]);
      } else {
        setCountrySelection((oldArray) => {
          let newArray = oldArray.filter(
            (item) => item.country_code !== selection.country_code,
          );
          if (
            newArray.length === 0 &&
            varietySelection.length === 0 &&
            typeSelection.length === 0 &&
            priceSelection[0] === priceData.min &&
            priceSelection[1] === priceData.max
          )
            setShowFiltering(false);
          return newArray;
        });
      }
    } else if (showFilter === 'Type' || filter === 'Type') {
      if (!contained) {
        setTypeSelection((oldArray) => [...oldArray, {...selection}]);
      } else {
        setTypeSelection((oldArray) => {
          let newArray = oldArray.filter((item) => item.id !== selection.id);
          if (
            newArray.length === 0 &&
            varietySelection.length === 0 &&
            countrySelection.length === 0 &&
            priceSelection[0] === priceData.min &&
            priceSelection[1] === priceData.max
          )
            setShowFiltering(false);
          return newArray;
        });
      }
    } else if (showFilter === 'Price' || filter === 'Price') {
      setPriceSelection([priceData.min, priceData.max]);
      if (
        typeSelection.length === 0 &&
        varietySelection.length === 0 &&
        countrySelection.length === 0
      )
        setShowFiltering(false);
    }
  };

  return (
    <>
      {favorites && <h2 style={{margin: '5%', marginTop: 20}}>Favorites </h2>}

      <Drawer
        anchor={'bottom'}
        open={state['form']}
        onClose={toggleDrawer('form', false)}
      >
        <ReserveForm
          toggleDrawer={toggleDrawer}
          favoritesList={favoritesList}
          bottleToReserve={bottleToReserve}
          setBottlesToReserve={setBottlesToReserve}
          restaurantId={id}
        />
      </Drawer>
      {chosenCat && (
        <h2
          style={{
            margin: '5%',
            marginTop: 60,
            fontSize: 22,
            position: 'relative',
          }}
        >
          Categories Selected:{' '}
          <span style={{fontSize: 18, fontWeight: 600}}>
            {chosenCat.map((cat, i) =>
              i <= 0 ? ' ' + cat.name : ', ' + cat.name,
            )}
          </span>
          <button
            className="filter_btn"
            onClick={() => showFullList()}
            style={{position: 'absolute', right: '5%', zIndex: 450}}
          >
            Clear
          </button>
        </h2>
      )}

      {!favorites && (
        <Fab
          style={{
            position: 'fixed',
            right: '2em',
            bottom: '85px',
            zIndex: 450,
            backgroundColor: '#238cd1',
            color: '#fff',
          }}
          onClick={
            !showFiltering
              ? toggleDrawer('bottom', true)
              : () => setShowFiltering(false)
          }
        >
          <FilterListIcon />
        </Fab>
      )}
      <Drawer
        anchor={'left'}
        open={state['left']}
        onClose={toggleDrawer('left', false)}
      >
        <CategoryPicker
          anchor={'left'}
          categories={categories}
          toggleDrawer={toggleDrawer}
          showFullList={showFullList}
          handleCategorySelection={handleCategorySelection}
          restaurantName={restaurantName}
          categorySelection={categorySelection}
          setCategorySelection={setCategorySelection}
        />
      </Drawer>
      {/* {!favorites && !chosenCat && (
        <h3 className="h3Style">
          <span
            style={{
              position: 'absolute',
              left: '5%',
              height: '100%',
              padding: '3px 0',
              boxSizing: 'border-box',
            }}
          >
            {restaurantLogo ? (
              <img
                style={{ height: '100%' }}
                src={restaurantLogo}
                alt={`${restaurantName} logo`}
              />
            ) : (
              restaurantName
            )}
          </span>{' '}
          {showFiltering && (
            <button
              className="filter_btn"
              onClick={showFiltering && toggleDrawer('bottom', true)}
              style={{
                position: 'absolute',
                right: '30%',
                marginLeft: '50%',
                top: '25%',
                bottom: '25%',
              }}
            >
              Edit
            </button>
          )}
          <button
            className="filter_btn"
            onClick={
              !showFiltering
                ? toggleDrawer('bottom', true)
                : () => setShowFiltering(false)
            }
            style={{
              position: 'absolute',
              right: '5%',
              top: '25%',
              bottom: '25%',
            }}
          >
            {showFiltering ? 'Clear' : 'Filter'}
          </button>
        </h3>
      )} */}
      {showFiltering && !favorites && (
        <h4 style={{margin: '5%'}}>
          Filter:
          {typeSelection?.length > 0 &&
            typeSelection.map((selType) => (
              <Chip
                icon={<TonalityIcon />}
                label={selType.name}
                clickable
                color="#9eb4f7"
                onDelete={() => userFilterSelection(selType, true, 'Type')}
              />
            ))}{' '}
          {varietySelection?.length > 0 &&
            varietySelection.map((selVariety) => (
              <Chip
                icon={<StyleIcon />}
                label={selVariety.name}
                clickable
                color="#9eb4f7"
                onDelete={() => userFilterSelection(selVariety, true, 'Grape')}
              />
            ))}{' '}
          {countrySelection?.length > 0 &&
            countrySelection.map((selCountry) => (
              <Chip
                icon={<MapIcon />}
                label={
                  (selCountry && selCountry.country_name) ||
                  (selCountry && selCountry.name)
                }
                clickable
                color="#9eb4f7"
                onDelete={() =>
                  userFilterSelection(selCountry, true, 'Country')
                }
              />
            ))}
          {priceSelection &&
            (priceSelection[0] !== priceData.min ||
              priceSelection[1] !== priceData.max) && (
              <Chip
                icon={<AttachMoneyIcon />}
                label={
                  priceSelection &&
                  `$${priceSelection[0]} - $${priceSelection[1]}`
                }
                clickable
                color="#9eb4f7"
                onDelete={() => {
                  userFilterSelection(priceSelection, true, 'Price');
                  setPriceSelection([priceData.min, priceData.max]);
                }}
              />
            )}
        </h4>
      )}

      {filteredList &&
        (filteredList.length === 0 ? (
          <p style={{marginLeft: '5%'}}>
            Filtered Everything out... Try Less Filter
          </p>
        ) : (
          ''
        ))}

      <div className="wineList_Container">
        {favorites && favoritesList.length === 0 && <p>No Favorites</p>}

        <div className="center_loading">
          <MagicSpinner size={80} color="lightblue" loading={loading} />
        </div>
        <WineList
          wineList={
            favorites
              ? favoritesList
              : filteredList
              ? filteredList
              : !state['top']
              ? wineList
              : []
          }
          handleFavorite={handleFavorite}
          favoritesList={favoritesList}
          showFiltering={showFiltering}
          countrySelection={countrySelection}
          typeSelection={typeSelection}
          varietySelection={varietySelection}
          bottleToReserve={bottleToReserve}
          setBottlesToReserve={setBottlesToReserve}
          favorites={favorites}
        />
        <div style={{textAlign: 'center', marginTop: '1.5em'}}>
          {favorites && favoritesList.length > 0 && bottleToReserve.length > 0 && (
            <Button
              variant="contained"
              color="primary"
              onClick={toggleDrawer('form', true)}
            >
              Reserve
            </Button>
          )}
          {favorites &&
            favoritesList.length > 0 &&
            bottleToReserve.length === 0 && (
              <small style={{color: 'rgba(0, 0, 0, 0.8)'}}>
                Please select at least one wine to reserve
              </small>
            )}
        </div>
      </div>
      <Drawer
        anchor={'bottom'}
        open={state['bottom']}
        onClose={toggleDrawer('bottom', false)}
      >
        <FilterModal
          toggleDrawer={toggleDrawer}
          anchor={'bottom'}
          varietyOptions={varietyOptions}
          countryOptions={countryOptions}
          typeOptions={typeOptions}
          setShowFiltering={setShowFiltering}
          setVarietySelection={setVarietySelection}
          varietySelection={varietySelection}
          setCountrySelection={setCountrySelection}
          countrySelection={countrySelection}
          typeSelection={typeSelection}
          setTypeSelection={setTypeSelection}
          priceData={priceData}
          priceSelection={priceSelection}
          setPriceSelection={setPriceSelection}
          handleFilterSelection={userFilterSelection}
          showFilter={showFilter}
          setShowFilter={setShowFilter}
        />
      </Drawer>
      <Fade in={state['top']}>
        <Paper elevation={4} className="search_modal">
          <SearchModal
            toggleDrawer={toggleDrawer}
            wineList={flatWineList}
            searchQuery={searchQuery}
            querying={querying}
            handleFavorite={handleFavorite}
            favoritesList={favoritesList}
            currentRestaurant={currentRestaurant}
          />
        </Paper>
      </Fade>
    </>
  );
}
