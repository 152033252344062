import axios from 'axios';

let BASE_URL = 'https://cliquot.bevrly.com';

if (process.env.REACT_APP_ENV === 'cliquot') {
  BASE_URL = 'https://cliquot.bevrly.com';
} else if (process.env.REACT_APP_ENV === 'production') {
  BASE_URL = 'https://bevrly.com';
}

class WineListAPI {
  constructor() {
    this.url = `${BASE_URL}/api`;
  }
  _get(url) {
    return axios.get(url).then((res) => {
      if (res.status >= 200) {
        return res.data;
      }
    });
  }
  _post(url, data) {
    return axios.post(url, data).then((res) => {
      return res.data;
    });
  }
  _put(url, data) {
    return axios.put(url, data).then((res) => {
      return res.data;
    });
  }
  getBevWineList(rest_id = 'bab38b5a-bf0d-4a5d-af88-434a9d589705') {
    let url = `${this.url}/beverage_list/` + rest_id;
    return this._get(url);
  }
  getCategoryList(rest_id = 'bab38b5a-bf0d-4a5d-af88-434a9d589705') {
    let url = `${this.url}/beverage_list/` + rest_id + '?include_wine=false';
    return this._get(url, {include_wine: false});
  }
  getFlatList(rest_id = 'bab38b5a-bf0d-4a5d-af88-434a9d589705') {
    let url = `${this.url}/beverage_list/` + rest_id + '?hierarchical=false';
    return this._get(url);
  }
  reserveItems(rest_id, bottleIds, name, phone, datetime, note) {
    let url = `${this.url}/reserve_items/` + rest_id;

    const payload = {
      bottleIds,
      name,
      phone,
      datetime,
      note,
    };

    return this._post(url, payload);
  }
}

export const wineListAPI = new WineListAPI();
