import * as Sentry from '@sentry/react';

if (
  process.env.NODE_ENV === 'production' ||
  process.env.REACT_APP_ENV === 'cliquot' ||
  process.env.REACT_APP_ENV === 'production'
) {
  let release = process.env.VERSION || 'unknown';

  Sentry.init({
    dsn:
      'https://143de8129be641afa73652168139b253@o309879.ingest.sentry.io/5406918',
    environment: process.env.REACT_APP_ENV,
    release,
  });
}
