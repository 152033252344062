import React, {useState, useEffect} from 'react';
import {Checkbox} from '@material-ui/core';

export function WineItem({
  category,
  wine = null,
  handleFavorite,
  favoritesList,
  search = false,
  bottleToReserve,
  setBottlesToReserve,
  favorites,
}) {
  const [favored, setFavored] = useState(false);
  const [newWineHtml, setNewWineHtml] = useState('');

  useEffect(() => {
    let containsFavor = false;
    favoritesList.forEach((favItem) => {
      if (
        (wine && favItem?.id === wine?.id) ||
        (category && favItem?.id === category?.id)
      ) {
        containsFavor = true;
      }
    });
    setFavored(containsFavor);
  }, [favoritesList, category.id, search, wine, category]); // any problems remove: category.id, search

  useEffect(() => {
    let wineName = category?.item_name
      ? category?.item_name
      : category?.wine?.name;
    if (wineName) {
      let newWineNameStruct = `<span>`;
      newWineNameStruct += wineName.replace(/\n/g, '<br />');
      newWineNameStruct += `</span>`;
      console.log(newWineNameStruct);
      setNewWineHtml(newWineNameStruct);
    }
  }, [category]);

  //let contained = bottleToReserve.inclues(category ? category.id : wine.id);
  //let contained = false;

  const handleReserving = (fav, contained = false) => {
    if (!bottleToReserve.some((item) => item.id === fav.id)) {
      setBottlesToReserve((oldBottles) => [...oldBottles, {...fav}]);
    } else {
      setBottlesToReserve((oldBottles) => {
        let newBottles = oldBottles.filter((item) => item.id !== fav.id);
        return [...newBottles];
      });
    }
  };

  let sizeName = '';
  let wineSize = '';

  if (category?.wine_bottle?.size?.name) {
    sizeName = category.wine_bottle.size.name.includes('🍷')
      ? ''
      : category.wine_bottle.size.name;
  }

  if (wine?.wine_bottle?.size?.name) {
    wineSize = wine.wine_bottle.size.name.includes('🍷')
      ? ''
      : wine.wine_bottle.size.name;
  }

  return (
    <div
      style={{
        marginLeft: !isNaN(category.depth ? category.depth * 4 : 4),
      }}
      key={'wine_item_' + category.item_name}
      className="Wine_Row_Container"
    >
      {favorites && (
        <Checkbox
          //checked={contained}
          name="fav_check"
          color="primary"
          style={{height: 15}}
          onChange={() => handleReserving(category ? category : wine)}
        />
      )}

      <div className="Name_Column">
        <>
          {category && category.item_name ? (
            <span>
              {category.wine.vintage && category.wine.vintage}{' '}
              <span dangerouslySetInnerHTML={{__html: newWineHtml}}></span>
              {category.wine_bottle.size.volume !== 750 && sizeName && (
                <>
                  {'- '}
                  <em>{sizeName}</em>
                </>
              )}
            </span>
          ) : (
            wine &&
            wine.item_name && (
              <span>
                {!wine.wine?.name && !wine.wine?.vintage && wine.item_name}
                {wine.wine?.vintage && wine.wine.vintage}{' '}
                {wine.item_name ? wine.item_name : wine.wine.name}{' '}
                {wine.wine_bottle.size.volume !== 750 && wineSize && (
                  <>
                    {'- '}
                    <em>{wineSize}</em>
                  </>
                )}
              </span>
            )
          )}
        </>
      </div>
      <div className="Price_Column">
        {' '}
        {category && category.price
          ? category.price
          : wine && wine.price
          ? wine.price
          : ' - '}
      </div>
      <div
        className="Star_Column"
        onClick={() => handleFavorite(wine ? wine : category, !favored)}
      >
        {favored ? (
          <i className="fas fa-star primary_color favorite"></i>
        ) : (
          <i className="far fa-star  primary_color favorite"></i>
        )}
      </div>
    </div>
  );
}
